@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@keyframes fade-in {
  0% {
    opacity: 0.5; /* L'opacité est à 0 au départ, l'image est invisible */
  }
  100% {
    opacity: 1; /* L'opacité est à 1 à la fin, l'image est complètement visible */
  }
}

/* Appliquez l'animation à l'image */
.entrance-image {
  animation: fade-in 1s ease-in-out; /* Nom de l'animation, durée, et fonction de temporisation */
}

/* Style utilisable sur les 2 page */
#fullHeightMap{
  font-family:'Poppins';
}

.titre{
  font-size: 20px;
  font-weight: bold;
  fill: #213D33;
}

.contenu{
  font-size: 20px;
  color: #213D33;
}

/* Style pour la map Agro */
.parcour-panneau-stage{
    font-weight: bold;
    font-size: 20px ;
    fill: white;
  }

  .parcour-3-panneau-details{
    fill: white;
    font-size: 20px;
  }

  .parcour-3-option-panneau-black{
    fill:#213D33;
    font-size: 20px;
  }

.panneau-1, .panneau-3, .panneau-5, .panneau-7, .panneau-12, .panneau-22{
  cursor: pointer;
}

text{
  cursor: default;
}