@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

html{
  max-width: 524px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#fullHeight {
  background-color: #183E32;
}

#fullHeightMap {
  position: relative;
  background-color: #183E32;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  font-size: calc(10px + 2vmin);
  z-index: 11;
}

.nav-bg {
  background-color: #183E32;
}

.image-header {
  position: relative;
  top: 0;
  border-radius: 0 0 35px 35px;
  height: calc(30vh * 1.1);
  /* overflow: hidden; */
  filter: brightness(90%);
}

.allYears {
  width: 60%;
}

.year {
  margin-left: 0.25em;
}

.h1 {
  color: white;
  font-family: 'Poppins', sans-serif;
}

.select-lang {
  color: #FFC43E;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

#fullHeight {
  padding-bottom: 7rem;
  min-height: 100vh;
  max-height: 300vh;
  overflow: hidden;
  font-size: 14px;
}

.home-card {
  background-color: #305549;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 14px;
  /* border: #707070 1px solid; */
}

.home-card-title {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: bold;
}

.home-card-title-color-a {
  color: #77A395;
  font-weight: bold;
  /* font-family: "Poppins", sans-serif; */
}

.home-card-title-color-b {
  color: #FFC43E;
}

.popin-bg{
  padding: 1rem;
}

.popin-content{
  color: white;
  font-size: 14px;
  border: none;
  background-color: rgb(119, 163, 149);
}

.character-container {
  border-radius: 10px;
  height: 11rem;
  position: relative;
  cursor: pointer;
}

.even-character {
  background-color: #77A395;
}

.odd-character {
  background-color: #FFC43E;
}

.image-card-bg{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 196, 62, 0.2);
  border-radius: 5px;
}

.tooltip-inner {
  background-color: #77A395;
}

.progress {
  background-color: rgba(119, 163, 149, 0.2);
}

.progress-bar {
  background-color: #FFC43E;
}

.App-link {
  color: #61dafb;
}

.parcour-panneau-stage {
  font-family: "Poppins";
  font-weight: bold;
  font-size: 20px;
  fill: white;
}

.parcour-panneau-stage-details {
  font-family: "Poppins";
  font-size: 20px;
  fill: white;
}

.parcour-3-panneau-details {
  font-size: 20px;
  font-family: "Poppins";
}

.parcour-3-panneau-alternance {
  font-size: 12px;
  font-family: "Poppins";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.previous-button {
  background-color: rgba(0, 0, 0, 0);
  color: #77A395;
  border: 3px solid #77A395;
  border-radius: 100%;
  width: 7vh;
  height: 7vh;
}

.next-button {
  background-color: rgba(0, 0, 0, 0);
  color: #77A395;
  border: 3px solid #77A395;
  border-radius: 100%;
  width: 7vh;
  height: 7vh;
}

.submit-button {
  background-color: rgba(0, 0, 0, 0);
  color: #FFC43E;
  border: 3px solid #FFC43E;
  border-radius: 30px;
  font-weight: bold;
  font-size: x-large;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.selected {
  background-color: rgba(255, 196, 62, 0.60);
}

.image-form-header {
  width: calc(30vh * 1.2);
  height: calc(30vh * 1.2);
  border-radius: 50%;
  background-color: #305549;
  overflow: hidden;
  position: relative;
}

.my-form-input {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  width: 100%;
  margin-top: 0.7rem;
}

.my-form-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.my-form-checkbox {
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
}

#rgpd {
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-radius: 0;
  margin-right: 0.5rem;
}

#rgpd:checked {
  background-color: #FFC43E;
}

.navbar {
  position: fixed;
  bottom: 0;
  max-width: 524px;
  background-color: #305549;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  z-index: 11;
}

.nav-shadow{
  box-shadow: 0px -1px 10px #183E32;
}

.cursor{
  cursor: pointer;
}

@media (min-width: 525px) {
  html {
    max-width: 900px;
  }
  .navbar{
    max-width: 900px;
  }

}